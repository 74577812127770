/* MuxPlayerCustomization.module.css */

.muxPlayerWrapper :global(mux-player) {
  aspect-ratio: 16/9;
}

.muxPlayerWrapper ::part(play):hover,
.muxPlayerWrapper ::part(seek-backward):hover,
.muxPlayerWrapper ::part(seek-forward):hover,
.muxPlayerWrapper ::part(time):hover,
.muxPlayerWrapper ::part(mute):hover,
.muxPlayerWrapper ::part(button):hover,
.muxPlayerWrapper ::part(playback-rate):hover,
.muxPlayerWrapper ::part(captions):hover,
.muxPlayerWrapper ::part(pip):hover,
.muxPlayerWrapper ::part(fullscreen):hover {
  background-color: transparent;
}
