/* Containers
---------------------------------------------- */
body {
  margin: 0;
  padding: 0;
}

.grecaptcha-badge {
  visibility: hidden;
}

/* Layout
---------------------------------------------- */
.gn-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.gn-nav,
.gn-footer {
  flex: 0 0 auto;
}

.gn-main {
  flex: 1;
}

/* Tyopgraphy
---------------------------------------------- */

.gn-font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* NOTE: font-italic refers to the Degular Display font; it is not a true italic of font-display */
.gn-page-header-lg {
  @apply font-italic;
  @apply font-black;
  @apply italic;
  @apply text-3xl;
  @apply lg:text-4xl;
  @apply xl:text-5xl;
}

.gn-page-header-sm {
  @apply font-italic;
  @apply font-black;
  @apply italic;
  @apply gn-font-smoothing;
  @apply text-2xl;
  @apply lg:text-3xl;
  @apply xl:text-4xl;
}

.gn-headline-lg {
  @apply font-italic;
  @apply font-black;
  @apply italic;
  @apply gn-font-smoothing;
  @apply text-xl;
  @apply md:text-2xl;
}

.gn-headline-md {
  @apply font-body;
  @apply font-extrabold;
  @apply gn-font-smoothing;
  font-size: 1.625rem;
  line-height: 1.75rem;
}

.gn-headline {
  @apply font-display;
  @apply font-black;
  @apply gn-font-smoothing;
}

.gn-headline-sm {
  @apply font-display;
  @apply font-black;
  @apply gn-font-smoothing;
  @apply text-xl;
}

.gn-subtitle {
  @apply font-body;
  @apply text-sm;
  @apply md:text-md;
  @apply lg:text-lg;
}

.gn-text-lg {
  @apply font-body;
  @apply text-lg;
}

.gn-text {
  @apply font-body;
  @apply text-md;
}

.gn-text-sm {
  @apply font-body;
  @apply text-sm;
}

.gn-text-xs {
  @apply font-body;
  @apply text-xs;
}

.gn-caption {
  @apply font-black;
  @apply font-body;
  @apply text-xs;
  text-transform: uppercase;
}

.gn-video-title {
  @apply font-display;
  @apply font-black;
  @apply text-lg;
}

.gn-word-break {
  word-break: normal;
  overflow-wrap: anywhere;
}

.gn-transmogrifier-video .jwplayer.jw-flag-aspect-mode {
  height: 100vh !important;
}

.gn-transmogrifier-video .jw-preview {
  display: none !important;
}
