@tailwind base;
@tailwind components;
@tailwind utilities;

/*TODO: Remove classes from the unused tab component first*/
.gn-tabs-font {
  color: white;
  font-size: 16px;
  font-weight: 700;
}

.gn-tabs-font:hover {
  color: #ffb81c;
  border-color: #ffb81c;
}

.gn-tabs-font-active {
  color: #ffb81c;
  border-color: #ffb81c;
}

input[type="number"].gn-reset-number-input {
  -moz-appearance: textfield;
}

input[type="number"].gn-reset-number-input::-webkit-inner-spin-button,
input[type="number"].gn-reset-number-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
