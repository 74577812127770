/* JWPlayerCustomization.module.css */

.jwPlayerWrapper :global(.jw-related-shelf-container) {
  display: none !important;
}

.jwPlayerWrapper :global(.jw-nextup-header),
.jwPlayerWrapper :global(.jw-nextup-title) {
  font-family: "Montserrat", sans-serif !important;
}

.jwPlayerWrapper :global(.jw-nextup-body) {
  background-color: #27272a !important;
}

.jwPlayerWrapper :global(.jw-nextup-thumbnail) {
  width: 35% !important;
}

.jwPlayerWrapper
  :global(.gonoodle-jwplayer-container:not(.gonoodle-jwplayer-no-controls)
    .jwplayer.jw-state-paused
    .jw-display),
.jwPlayerWrapper
  :global(.gonoodle-jwplayer-container:not(.gonoodle-jwplayer-no-controls)
    .jwplayer:not(.jw-flag-user-inactive):hover
    .jw-display) {
  display: flex !important;
}

.jwPlayerWrapper
  :global(.gonoodle-jwplayer-container:not(.gonoodle-jwplayer-no-controls)
    .jwplayer.jw-state-paused:not(.jw-flag-touch):not(.jw-flag-small-player)
    .jw-display
    .jw-display-icon-container:not(.jw-display-icon-display)),
.jwPlayerWrapper
  :global(.gonoodle-jwplayer-container:not(.gonoodle-jwplayer-no-controls)
    .jwplayer:not(.jw-flag-user-inactive):not(.jw-flag-touch):not(.jw-flag-small-player):hover
    .jw-display
    .jw-display-icon-container:not(.jw-display-icon-display)) {
  display: none !important;
}

.jwPlayerWrapper
  :global(.gonoodle-jwplayer-container:not(.gonoodle-jwplayer-no-controls)
    .jwplayer.jw-state-paused
    .jw-controls-backdrop),
.jwPlayerWrapper
  :global(.gonoodle-jwplayer-container:not(.gonoodle-jwplayer-no-controls)
    .jwplayer:not(.jw-flag-user-inactive):hover
    .jw-controls-backdrop) {
  opacity: 0 !important;
}

.jwPlayerWrapper
  :global(.gonoodle-jwplayer-container:not(.gonoodle-jwplayer-no-controls)
    .jw-controls) {
  transition: background 250ms cubic-bezier(0, 0.25, 0.25, 1);
}

.jwPlayerWrapper
  :global(.gonoodle-jwplayer-container:not(.gonoodle-jwplayer-no-controls)
    .jwplayer.jw-state-paused
    .jw-controls),
.jwPlayerWrapper
  :global(.gonoodle-jwplayer-container:not(.gonoodle-jwplayer-no-controls)
    .jwplayer:not(.jw-flag-user-inactive):hover
    .jw-controls) {
  background: rgba(0, 0, 0, 0.4);
}

.jwPlayerWrapper
  :global(.gonoodle-jwplayer-no-controls .jw-svg-icon:not(.jw-svg-icon-buffer)),
.jwPlayerWrapper
  :global(.gonoodle-jwplayer-no-controls
    .jw-display-icon-display:has(.jw-icon-display:not([aria-label="Loading"]))),
.jwPlayerWrapper
  :global(.gonoodle-jwplayer-no-controls .jw-display-icon-rewind),
.jwPlayerWrapper :global(.gonoodle-jwplayer-no-controls .jw-display-icon-next),
.jwPlayerWrapper :global(.gonoodle-jwplayer-no-controls .jw-controlbar),
.jwPlayerWrapper :global(.gonoodle-jwplayer-no-controls .jw-controls-backdrop),
.jwPlayerWrapper :global(.gonoodle-jwplayer-no-controls .jw-settings-menu),
.jwPlayerWrapper :global(.gonoodle-jwplayer-no-controls .jw-nextup-container) {
  display: none !important;
}

.jwPlayerWrapper
  :global(.gonoodle-jwplayer-no-controls
    .jwplayer.jw-state-complete
    .jw-controls) {
  background: none !important;
}
