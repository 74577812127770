:root {
  /* --------------------------------------------
  VARIABLE NAMING GUIDELINES
  --[element]-[state]-[css-property]-[variant]
  --card-border;
  --card-hover-border;
  --card-hover-border-lg;
  --------------------------------------------- */

  /* Screens
  -------------------------------------------- */
  --screen-xs: 420px;
  --screen-sm: 640px;
  --screen-md: 768px;
  --screen-lg: 1024px;
  --screen-xl: 1280px;
  --screen-2xl: 1440px;
  --screen-3xl: 1920px;

  /* Spacing
  -------------------------------------------- */
  --space-xs: 4px;
  --space-sm: 12px;
  --space-md: 24px;
  --space-lg: 40px;
  --space-xl: 64px;
  --space-2xl: 128px;
  --space-3xl: 250px;

  /* Height
  -------------------------------------------- */
  --navbar-height: 72px;
  --navbar-lg: 620px;
  --navbar-md: 540px;
  --navbar-default: 260px;

  --header-height-xl: 43vw;
  --header-height-lg: 580px;
  --header-height-md: 540px;
  --header-height-sm: 340px;
  --header-height-xs: 260px;

  --button-height-xl: 60px;
  --button-height-lg: 50px;
  --button-height-md: 42px;
  --button-height-sm: 32px;

  --input-height: 42px;
  --video-title-height: 80px;

  --input-icon-height: 20px;

  /* Colors
  -------------------------------------------- */

  /* Palette ------------- */

  /* Neutral Colors */
  --color-white: #fff;

  /* Black Shades */
  --color-black-950: #000000; /* Pure black */
  --color-black-900: #1a1a1a; /* Merged from --sn-color-black */

  /* Special Whites */
  --color-off-white: #fdfdfc; /* Merged from --sn-color-white*/

  /* Orange Shades */
  --color-orange-950: #f4ba40;
  --color-orange-900: #f6c860;
  --color-orange-800: #f09665;
  --color-orange-700: #f8d688;
  --color-orange-600: #f3b08c;
  --color-orange-500: #fae3b0;
  --color-orange-400: #f6cbb2;
  --color-orange-300: #fbe5d9;
  --color-orange-200: #fdf2ec;
  --color-orange-100: #fef8eb;
  --color-orange-50: #fdf2ec;

  /* Gray Shades */
  --color-gray-900: #18181b;
  --color-gray-800: #27272a;
  --color-gray-700: #3f3f46;
  --color-gray-500: #71717a;
  --color-gray-300: #d4d4d8;
  --color-gray-100: #f4f4f5;

  /* Purple Shades */
  --color-purple-900: #350099;
  --color-purple-800: #401787; /* New color */
  --color-purple-700: #4e00e0;
  --color-purple-600: #7328f5; /* Merged from --sn-color-bright-purple-100 */
  --color-purple: #7328ff;
  --color-purple-400: #9258ff;
  --color-purple-300: #ccb4fb;
  --color-purple-200: #ebecfb; /* Merged from --sn-color-neutral-purple-30 */
  --color-purple-100: #f0e9ff;

  /* Pink Shades */
  --color-pink: #e10098;
  --color-pink-300: #ecb5da;
  --color-pink-100: #ffe5f7;

  /* Green Shades */
  --color-green: #00cf91;

  /* Mint Shades */
  --color-mint: #00e0a6;
  --color-mint-300: #98e9d4;
  --color-mint-100: #e3f3ef;

  /* Yellow Shades */
  --color-yellow: #ffe700;
  --color-yellow-300: #fff7aa;
  --color-yellow-100: #fffbd6;

  /* Gold Shades */
  --color-gold: #ffb81c;
  --color-gold-300: #ffe5ad;
  --color-gold-100: #fff6e2;

  /* Blue Shades */
  --color-blue: #147bd1;
  --color-blue-300: #84b3ff;
  --color-blue-200: #bddef5;
  --color-blue-100: #e7f0ff;

  /* Red Shades */
  --color-red: #e31c3d;
  --color-red-100: #fca5a5;

  /* UI Colors ----------- */
  --color-danger: var(--color-red);
  --color-danger-100: var(--color-red-100);

  --color-focus: var(--color-purple);

  /* Typography
  -------------------------------------------- */

  /* Font Size ----------- */
  --font-size-xxs: 10px;
  --font-size-xs: 12px;
  --font-size-sm: 14px;
  --font-size-md: 16px;
  --font-size-lg: 22px;
  --font-size-xl: 30px;
  --font-size-2xl: 42px;
  --font-size-3xl: 54px;
  --font-size-4xl: 72px;
  --font-size-5xl: 106px;

  /* Font Weight --------- */
  --font-weight-regular: 400;
  --font-weight-bold: 700;
  --font-weight-extrabold: 800;
  --font-weight-black: 900;

  /* Line Height --------- */
  --line-height-xxs: 14px;
  --line-height-xs: 16px;
  --line-height-sm: 18px;
  --line-height-md: 26px;
  --line-height-lg: 36px;
  --line-height-xl: 52px;
  --line-height-2xl: 84px;

  /* Borders
  -------------------------------------------- */
  --border-radius: 4px;
  --border-radius-lg: 8px;
  --border-width-sm: 1px;
  --border-width: 2px;
}
